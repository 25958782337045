// Utilities
import axios from '../../services/http-common';

let map = JSON.parse(localStorage.getItem('mapCenter'));
if (!(map && map.lat !== 0 && map.lng !== 0)) {
    map = { lat: -33.9199425, lng: 151.0139639 }
}
//console.log(map);
const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    user: localStorage.getItem('username'),
    profile: {},
    mapCenter: map
}

const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, user) {
        state.status = 'success'
        state.token = user.accessToken
        state.refreshToken = user.refreshToken
        state.user = user.user
        try {
            this._vm.$signalR.setupSignalRConnection(user.accessToken);
        } catch (error) {
            console.log(error);
        }
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
        try {
            this._vm.$signalR.stopConnection();
        } catch (error) {
            console.log(error);
        }
    },
    profile(state, data) {
        state.profile = Object.assign({}, data.profile);
        if (data.mapCenter.lat !== 0, data.mapCenter.lng !== 0) {
            state.mapCenter = Object.assign({}, data.mapCenter);
        }
    },
}

const actions = {
    login: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/Authentication/login', user)
                .then((response) => {
                    if (response.data.user) {
                        const userData = response.data
                        localStorage.setItem('token', userData.accessToken)
                        localStorage.setItem('refreshToken', userData.refreshToken)
                        localStorage.setItem('username', userData.user);
                        commit('auth_success', userData)
                        resolve(response)
                    } else {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        localStorage.removeItem('refreshToken')
                        localStorage.removeItem('username')
                        reject({ isMessage: true, message: response.data.message })
                    }
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('username')
                    reject({ isMessage: false, message: err })
                })
        })
    },

    getAccessToken: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/Authentication/RefreshToken', { token: localStorage.getItem('refreshToken') })
                .then((response) => {
                    const userData = response.data
                    localStorage.setItem('token', userData.token)
                    localStorage.setItem('user', userData.user)
                    localStorage.setItem('refreshToken', userData.refreshToken)
                    commit('auth_success', userData)
                    resolve(response)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('user')
                    reject(err)
                })
        })
    },

    logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('username')
            resolve()
        })
    },

    completeLogout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('logout')
            axios.post('/Authentication/logout').then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('username')
                resolve()
            }).catch(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('username')
                reject();
            })
        })
    },

    getProfile: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get("/Profile")
                .then((response) => {
                    let profile = response.data.data;
                    let mapCenter = { lat: Number("" + profile.mapLatitude), lng: Number("" + profile.mapLongitude) }
                    localStorage.setItem('mapCenter', JSON.stringify(mapCenter))
                    commit('profile', { profile, mapCenter })
                    resolve(profile)
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },
    updateProfile: ({ commit }, profile) => {
        return new Promise((resolve, reject) => {
            axios.post("/Profile", profile).then((response) => {
                if (response.data.status === "success") {
                    let mapCenter = { lat: Number("" + profile.mapLatitude), lng: Number("" + profile.mapLongitude) }
                    localStorage.setItem('mapCenter', JSON.stringify(mapCenter))
                    commit('profile', { profile, mapCenter })
                    resolve(response.data.message)
                } else {
                    reject(response.data.message)
                }
            }).catch(() => { });
        });
    }
}

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
