import axios from "../../services/http-common"

const state = {
    totalMessages: 0,
    deviceMessages: [],
    driverMessages: [],
}

const mutations = {
    addMessage(state, obj) {
        if (obj.message && obj.messageId) {
            state.deviceMessages = [...state.deviceMessages, obj];
            state.totalMessages += 1;
        }
    },
    addDriverMessage(state, obj) {
        if (obj.message && obj.messageId) {
            state.driverMessages = [...state.driverMessages, obj];
            state.totalMessages += 1;
        }
    },
    messageDeliveredToDevice(state, messageId) {
        state.deviceMessages = state.deviceMessages.map(x => {
            if (x.messageId === messageId) {
                x.status = true;
                x.statusInText = "Delivered";

                this._vm.$toast.success('Message sent successfully.', "Success", {
                    position: "topRight",
                });
            }
            return x;
        });
    },
    MessageDeliveredToUser(state, messageId) {
        state.driverMessages = state.driverMessages.map(x => {
            if (x.messageId === messageId) {
                x.status = true;
                x.statusInText = "Delivered";

                this._vm.$toast.success('Message sent successfully.', "Success", {
                    position: "topRight",
                });
            }
            return x;
        });
    }
}

const actions = {
    sendMessageToDevice: ({ commit }, obj) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await axios
                    .post("/Message/SendMessageToDevice", {
                        macAddress: obj.macAddress,
                        message: obj.message,
                    });
                if (response.data.success) {
                    commit('addMessage', {
                        macAddress: obj.macAddress,
                        message: obj.message,
                        messageId: response.data.messageId,
                        status: false,
                        statusInText: "Pending"
                    })
                }
                resolve(response)
            } catch (error) {
                reject(error.response)
            }
        });
    },
    messageDeliveredToDevice: ({ commit }, messageId) => {
        if (messageId) {
            commit("messageDeliveredToDevice", messageId)
        }
    },
    sendMessageToDriver: ({ commit }, obj) => {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await obj.context.$locationHub
                    .sendMessage(obj.username, obj.message);
                console.log(result);
                if (result.success) {
                    commit('addDriverMessage', {
                        username: obj.username,
                        message: obj.message,
                        messageId: result.messageId,
                        status: false,
                        statusInText: "Pending"
                    })
                    setTimeout(() => {
                        let message = state.driverMessages.find(x => x.messageId === result.messageId);
                        if (!message.status) {
                            obj.context.$toast.error("Unable to reach the device/tablet, please check if the tablet is powered on and its connected to the internet.", "Error", {
                                position: "topRight",
                            });
                        }
                    }, 5000)
                }
                resolve(result)
            } catch (error) {
                reject(error)
            }
        });
    },
    MessageDeliveredToUser: ({ commit }, messageId) => {
        if (messageId) {
            commit("MessageDeliveredToUser", messageId)
        }
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}