<template>
  <v-container>
    <v-row align="center">
      <span style="background: #f4f5f5; padding: 0 7.5px; border-radius: 15px">
        Copyright &copy; {{ currentYear }}
        <a
          id="companyLink"
          class="secondary--text"
          href="https://www.softclient.com.au/"
          >www.softclient.com.au</a
        >
        all rights reserved.
      </span>
      <v-col class="text-right">
        <!-- <span>Version 21.09.24.00</span> -->
        <span style="background: #f4f5f5; padding: 0 7.5px; border-radius: 15px"
          >Version B.E.{{ bVersion }} : F.E.{{ fVersion }}</span
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { version } from "../../package";
export default {
  name: "Links",

  data: () => ({
    bVersion: "",
    fVersion: version,
    currentYear: new Date().getFullYear(),
  }),
  created() {
    this.getVersion();
  },
  methods: {
    getVersion() {
      this.$http
        .get("/Version")
        .then((response) => {
          this.bVersion = response.data.version;
          let frontVersion = localStorage.getItem("fVersion");
          let backVersion = localStorage.getItem("bVersion");
          if (frontVersion && backVersion) {
            if (
              frontVersion !== this.fVersion ||
              backVersion !== this.bVersion
            ) {
              window.location.reload();
            }
          }
          localStorage.setItem("fVersion", this.fVersion);
          localStorage.setItem("bVersion", this.bVersion);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="sass" scoped>
#companyLink
  text-decoration: none
</style>
