// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import('../layouts/default/Index.vue'),
      children: [
        {
          path: '/',
          name: 'Dashboard',
          component: () => import('../views/Dashboard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/bookings',
          name: 'Bookings',
          component: () => import('../views/Bookings.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/drivers',
          name: 'Drivers',
          component: () => import('../views/Driver.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/passengers',
          name: 'Passengers',
          component: () => import('../views/Customer.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/taxis',
          name: 'Taxis',
          component: () => import('../views/Taxi.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/vehicle-types',
          name: 'VehicleTypes',
          component: () => import('../views/VehicleType.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/vehicle-type/:id/fares',
          name: 'FareDetails',
          component: () => import('../views/FareDetails.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/fares',
          name: 'Fares',
          component: () => import('../views/Fare.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/special-fares',
          name: 'SpecialFares',
          component: () => import('../views/SpecialFares.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/settings',
          name: 'Settings',
          component: () => import('../views/Settings.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('../views/UserProfile.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/users',
          name: 'Users',
          component: () => import('../views/Users.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/devices',
          name: 'Devices',
          component: () => import('../views/Devices.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/messages',
          name: 'Messages',
          component: () => import('../views/GroupMessages.vue'),
          meta: {
            requiresAuth: false,
          },
        },

      ],
    },
    {
      path: '',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import('../views/Login'),
        }
      ],
    },
    {
      path: '*',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '',
          name: 'Error',
          component: () => import('../views/Error.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (from.path !== to.path || from.name !== to.name) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    } else {
      next()
    }
  }
})

export default router
