import store from './index'

export default async () => {
    try {
        //get profile
        store.dispatch('authUser/getProfile').catch(() => { });
        //taxis
        let drivers = store.dispatch('taxi/loadTaxis')
    } catch (err) {
        console.log(err);
    }
}