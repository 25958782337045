// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: true,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      items: [{
        title: 'Filters',
        items: []
      },],
      to: '/',
    },

    {
      title: 'Bookings',
      icon: 'mdi-calendar-month ',
      to: '/bookings',
    },
    {
      title: 'Taxis',
      icon: 'mdi-taxi ',
      to: '/taxis',
    },
    {
      title: 'Drivers',
      icon: 'mdi-card-account-details-outline ',
      to: '/drivers',
    },
    {
      title: 'Passengers',
      icon: 'mdi-account-group-outline ',
      to: '/passengers',
    },
    {
      title: 'VehicleTypes',
      icon: 'mdi-car-estate ',
      to: '/vehicle-types',
    },
    {
      title: 'Fares',
      icon: 'mdi-currency-usd ',
      to: '/fares',
    },
    {
      title: 'SpecialFares',
      icon: 'mdi-decagram  ',
      to: '/special-fares',
    },

    // {
    //   title: 'Payments',
    //   icon: 'mdi-credit-card-outline ',
    //   to: '/payments',
    // },
    // {
    //   title: 'Reports',
    //   icon: 'mdi-file-chart ',
    //   to: '/reports',
    // },
    {
      title: 'Message Groups',
      icon: 'mdi-message-processing-outline  ',
      to: '/messages',
    },
    {
      title: 'Users',
      icon: 'mdi-account-tie ',
      to: '/users',
    },
  ],

}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
