<template>
  <v-dialog persistent v-model="dialog" max-width="350px">
    <v-card>
      <v-card-title class="headline">
        <div style="display: flex">
          <img src="../../assets/comments.png" width="48" alt="" />
          <div class="mt-1 ml-2">Message</div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          <div>{{ formatDate(time) }}</div>
          <div style="font-size: 18px" class="mt-2">
            <div>
              <strong> Message from {{ sender }}: {{ message }}</strong>
            </div>
            <div>DriverNo:{{ driver.driverNo }}</div>
            <div>HomeCity:{{ driver.homeCity }}</div>
            <div>VehicleNo:{{ driver.vehicleNo }}</div>
            <div>PhoneNo:{{ driver.phoneNumber }}</div>
            <div>Address:{{ driver.address }}</div>
          </div>
          <v-btn
            rounded
            width="200"
            class="mt-2"
            color="success"
            @click="close"
          >
            OK
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "MessageAlert",
  data: () => ({
    dialog: false,
    message: "",
    time: "",
    sender: "",
    driver: {
      driverNo: "",
      homeCity: "",
      address: "",
      phoneNumber: "",
      vehicleNo: "",
    },
    defaultDriver: {
      driverNo: "",
      homeCity: "",
      address: "",
      phoneNumber: "",
      vehicleNo: "",
    },
  }),
  created() {
    this.$locationHub.$on("NewMessage", this.newMessage);
  },
  onDestroy() {
    this.$locationHub.$off("NewMessage", this.newMessage);
  },
  computed: {
    audio() {
      return new Audio(
        this.$http.defaults.baseURL.replace(
          "/api/",
          "/Resources/Sound/Message.mp3"
        )
      );
    },
    drivers() {
      return this.$store.state.taxi.activeDrivers;
    },
  },
  methods: {
    newMessage(data) {
      this.audio.play();
      if (this.drivers) {
        let driver = this.drivers.find((x) => x.username === data.senderName);
        if (driver) {
          this.driver = Object.assign({}, driver);
        } else {
          this.driver = Object.assign({}, defaultDriver);
        }
      }
      console.log("messageAlert", data);
      this.sender = data.senderName;
      this.time = data.sentTime;
      this.message = data.message;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.audio.pause();
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
  },
};
</script>
