<template>
  <v-dialog persistent v-model="emergency" max-width="500px">
    <v-card>
      <v-card-title class="headline"> Emergency </v-card-title>
      <v-card-text>
        <div class="text-center">
          <img src="../../assets/siren.png" alt="" width="100" />
          <div style="font-size: 18px" class="mt-2">
            <strong>Message from {{ driver }}: {{ currentMessage }}</strong>
            <div>Vehicle no: {{ vehicleNo }}</div>
            <div>Phone no: {{ phoneNo }}</div>
            <div>{{ address }}</div>
          </div>
          <v-btn
            width="200"
            class="mt-5"
            color="primary"
            @click="closeEmergency"
          >
            OK
          </v-btn>
        </div>
      </v-card-text>
      <!-- <v-card-actions>
          <div class="text-right">
            <v-btn color="blue darken-1" text @click="emergency = false">
              OK
            </v-btn>
          </div>
        </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EmergencyAlert",
  data: () => ({
    emergency: false,
    driver: "",
    vehicleNo: "",
    currentMessage: "",
    phoneNo: "",
    address: "",
    audio: new Audio(
      "https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_emergency_alarm_siren_high_pitched_beeps_danger_68955.mp3"
    ),
  }),
  created() {
    this.$locationHub.$on("NewEmergency", this.newMessage);
  },
  onDestroy() {
    this.$locationHub.$off("NewEmergency", this.newMessage);
  },

  methods: {
    newMessage(data) {
      console.log("EmergencyAlert ", data);
      if (data) {
        this.currentMessage = data.message;
        this.driver = data.driverUsername;
        this.vehicleNo = data.vehicleRego;
        this.phoneNo = data.phoneNumber;
        this.address = data.address;

        this.emergency = true;
        this.audio.play();
      }
    },
    closeEmergency() {
      this.emergency = false;
      this.audio.pause();
      this.audio.currentTime = 0;
    },
  },
};
</script>
