import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import axios from './src/services/http-common';
import store from './src/store';
//connecting: 0, connected: 1, reconnecting: 2, disconnected: 4
export default {
    install(Vue) {
        var baseUrl = axios.defaults.baseURL.replace('/api/', '');
        var hubConnection = null;
        const LocationHub = new Vue();
        Vue.prototype.$locationHub = LocationHub;

        const signalR = {
            setupSignalRConnection(token) {
                let signalRUrl = baseUrl + "/hubs/device?access_token=" + token;

                hubConnection = new HubConnectionBuilder()
                    .withUrl(signalRUrl)
                    .withAutomaticReconnect([0, 5000, 10000, 30000])
                    .configureLogging(LogLevel.Information)
                    .build();

                hubConnection.serverTimeoutInMilliseconds = 120 * 1000;

                hubConnection.on('ReceiveVehicleLocation', (username, latitude, longitude, address) => {
                    let taxi = store.getters["taxi/getTaxiByUsername"](username);
                    let vehicleNo = taxi?.vehicleNo ?? '';
                    let version = taxi?.appVersion ?? "";
                    console.log("ReceiveDriverLocation" + new Date(), { username, latitude, longitude, address, vehicleNo, version });
                    LocationHub.$emit('location-changed', { username, latitude, longitude, address });
                    store.dispatch('taxi/updateDriver', { username, latitude, longitude, address })
                })

                hubConnection.on("DriverUpdated", (driver) => {
                    console.log('DriverUpdated', driver);
                    store.dispatch('taxi/updateDriver', driver)
                });

                hubConnection.on("DriverOnline", (driver) => {
                    console.log('DriverOnline', driver);
                    LocationHub.$emit('driver-status-changed', driver);
                    store.dispatch('taxi/updateDriver', driver)
                });

                hubConnection.on("DriveOffline", (username) => {
                    console.log('DriveOffline', username);
                    LocationHub.$emit('driver-status-changed', { username, driverStatus: 2 });
                    store.dispatch('taxi/updateDriver', { username, driverStatus: 2 })
                });

                hubConnection.on("DriverLoggedOut", (username) => {
                    console.log('DriverLoggedOut', username);
                    LocationHub.$emit('driver-status-changed', { username, driverStatus: 1 });
                    store.dispatch('taxi/updateDriver', { username, driverStatus: 1 })
                });

                hubConnection.on("DriverActive", (driver) => {
                    console.log('DriverActive', driver);
                    LocationHub.$emit('driver-status-changed', driver);
                    store.dispatch('taxi/updateDriver', driver)
                });

                hubConnection.on("DriveInActive", (username) => {
                    console.log('DriveInActive', username);
                    LocationHub.$emit('driver-status-changed', { username, driverStatus: 4 });
                    store.dispatch('taxi/updateDriver', { username, driverStatus: 4 })
                });

                hubConnection.on("JobStatusChanged", (jobId, driverId, status) => {
                    console.log('JobStatusChanged', { jobId, driverId, status });
                    let driver = store.getters["taxi/getTaxiByDriverId"](driverId);
                    if (status === 4) {
                        if (driver) {
                            LocationHub.$emit('driver-status-changed', { username: driver.username, driverStatus: 8 });
                        }
                        store.dispatch('taxi/updateDriver', { username: driver.username, driverStatus: 8 })
                    }
                    if (status === 6) {
                        if (driver) {
                            LocationHub.$emit('driver-status-changed', { username: driver.username, driverStatus: 7 });
                        }
                        store.dispatch('taxi/updateDriver', { username: driver.username, driverStatus: 7 })
                    }
                    if (status === 8) {
                        if (driver) {
                            LocationHub.$emit('driver-status-changed', { username: driver.username, driverStatus: 5 });
                        }
                        store.dispatch('taxi/updateDriver', { username: driver.username, driverStatus: 5 })
                    }
                    LocationHub.$emit("JobStatusChanged", { jobId, driverId, status });
                });

                hubConnection.on("JobAddedToQueue", (jobId) => {
                    console.log('JobAddedToQueue', jobId);
                    LocationHub.$emit('newJob', jobId);
                });

                hubConnection.on('Notification', (message, jobId, driverId) => {
                    LocationHub.$emit('Notification', { message, jobId, driverId })
                });

                hubConnection.on("NewMessage", (data) => {
                    LocationHub.$emit("NewMessage", data);
                });

                hubConnection.on("AdminAlertMessage", data => {
                    let taxi = store.getters["taxi/getTaxiByUsername"](data.driverUsername);
                    if (taxi) {
                        console.log("AdminAlertMessage", data, taxi);
                        data.vehicleRego = data.vehicleRego || taxi.vehicleNo;
                        data.address = data.address || taxi.address
                        data.phoneNumber = data.phoneNumber || taxi.phoneNumber
                    }
                    LocationHub.$emit("NewEmergency", data);
                })

                hubConnection.on("NewDevice", device => {
                    console.log("NewDevice", device);
                    store.dispatch('taxi/addNewTaxi', device)
                })

                hubConnection.on("DeviceUpdated", (device) => {
                    console.log('DeviceUpdated', device);
                    store.dispatch('taxi/updateDevice', device)
                });

                hubConnection.on("ActiveDevice", macAddress => {
                    console.log("ActiveDevice", macAddress);
                    LocationHub.$emit('device-status-changed', { macAddress, active: true });
                    store.dispatch('taxi/updateDevice', { macAddress, active: true })
                })

                hubConnection.on("InActiveDevice", macAddress => {
                    console.log("InActiveDevice", macAddress);
                    LocationHub.$emit('device-status-changed', { macAddress, active: false });
                    store.dispatch('taxi/updateDevice', { macAddress, active: false })
                })

                hubConnection.on("ReceiveDeviceLocation", (macAddress, latitude, longitude, address) => {
                    let taxi = store.getters["taxi/getTaxiByMacAddress"](macAddress);
                    let vehicleNo = taxi?.vehicleNo ?? '';
                    let version = taxi?.appVersion ?? "";
                    console.log("ReceiveDeviceLocation" + new Date(), { macAddress, latitude, longitude, address, vehicleNo, version });
                    LocationHub.$emit('device-location-changed', { macAddress, latitude, longitude, address });
                    store.dispatch('taxi/updateDevice', { macAddress, latitude, longitude, address })
                })

                hubConnection.on("MessageDeliveredToDevice", (messageId) => {
                    console.log("MessageDeliveredToDevice", messageId);
                    store.dispatch('message/messageDeliveredToDevice', messageId);
                })

                hubConnection.on("MessageDeliveredToUser", (messageId) => {
                    console.log("MessageDeliveredToUser", messageId);
                    store.dispatch('message/MessageDeliveredToUser', messageId);
                })

                LocationHub.sendMessage = (username, message) => {
                    if (hubConnection.state === HubConnectionState.Connected) {
                        return hubConnection.invoke("SendMessageToUser", message, username);
                    } else {
                        return null;
                    }
                };

                hubConnection.onreconnecting(error => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Reconnecting);
                    console.log("SignalR is Reconnecting...", error);
                });

                hubConnection.onreconnected(connectionId => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Connected);
                    console.log("SignalR Reconnected with " + connectionId);
                });

                hubConnection.onclose(error => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Disconnected);
                    console.log("SignalR Disconnected. ", error);
                });

                return this.startSignalRConnection(hubConnection);
            },

            async startSignalRConnection(connection) {
                try {
                    await connection.start();
                    this.updateSignalRStatus();
                    console.assert(connection.state === HubConnectionState.Connected)
                    // this.getAllDrivers();
                    return Promise.resolve("SignalR Connected.")
                } catch (err) {
                    this.updateSignalRStatus();
                    console.log(err);
                    console.assert(connection.state === HubConnectionState.Disconnected)
                    axios.get('/Authentication/Authenticate').then(() => {
                        setTimeout(() => this.startSignalRConnection(connection), 5000);
                    }).catch(() => { });
                    return Promise.reject("SignalR not Connected")
                }
            },

            async stopConnection() {
                if (hubConnection !== null) {
                    if (hubConnection.state === HubConnectionState.Connected) {
                        hubConnection.stop();
                    }
                }
            },

            // getAllDrivers() {
            //     if (hubConnection.state === HubConnectionState.Connected) {
            //         // hubConnection.invoke('GetAllDriversWithStatus').then(drivers => {
            //         //     // console.log(drivers);
            //         //     store.dispatch('driver/getAllActiveDriver', drivers)
            //         // }, (err) => {
            //         //     console.log('GetAllDriversWithAllDetails:', err)
            //         // });
            //         axios.get('/Driver/GetAllDriversWithStatus').then(response => {
            //             store.dispatch('driver/getAllActiveDriver', response.data)
            //         })
            //     } else {
            //         console.log('GetAllDriversWithAllDetails:', 'signalR not Connected')
            //     }
            // },

            forceLogoutUser(user) {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (hubConnection.state === HubConnectionState.Connected) {
                            await hubConnection.send('ForceLogoutUser', user);
                            resolve('logged out')
                        } else {
                            reject('forceLogoutUser:', 'signalR not Connected')
                        }
                    } catch (error) {
                        reject(error)
                    }
                })
            },

            updateSignalRStatus() {
                if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
                    LocationHub.$emit('signal-r-status-changed', true);
                } else {
                    LocationHub.$emit('signal-r-status-changed', false);
                }
            },

            connection: hubConnection,
            connectionState: HubConnectionState
        }

        axios.get("/Authentication/Authenticate").then(() => {
            if (signalR.connection === null) {
                signalR.setupSignalRConnection(store.state.authUser.token);
            } else {
                if (
                    signalR.connection.state !==
                    signalR.connectionState.Connected
                ) {
                    signalR.setupSignalRConnection(
                        store.state.authUser.token
                    );
                }
            }
        }).catch(() => { });

        Vue.prototype.$signalR = signalR;
    }
}