import axios from '../../services/http-common';
import { make } from 'vuex-pathify'

const state = {
    statuses: [
        {
            id: true,
            text: "Active",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/active-24.png"),
            color: "#f9c50d",
        },
        {
            id: false,
            text: "InActive",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/inactive-24.png"),
            color: "#a58b93",
        },
        {
            id: 1,
            text: "Logged out",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/logged-out-24.png"),
            color: "#0e8ce6",
        },
        {
            id: 2,
            text: "Offline",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/offline-24.png"),
            color: "#41cf25",
        },
        {
            id: 3,
            text: "Online",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/online-24.png"),
            color: "#07e6a6",
        },
        {
            id: 4,
            text: "Off duty",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/off-duty-24.png"),
            color: "#a86632",
        },
        {
            id: 5,
            text: "Waiting for jobs",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/waiting-for-jobs-24.png"),
            color: "#07d7e6",
        },
        {
            id: 6,
            text: "On the way to pickup",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/on-the-way-to-pickup-24.png"),
            color: "#dbeb2a",
        },
        {
            id: 7,
            text: "On the way to drop",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/on-the-way-to-drop-24.png"),
            color: "#e69a0e",
        },
        {
            id: 8,
            text: "On the way to pickup",
            icon: axios.defaults.baseURL.replace('/api/', "/Resources/Icons/on-the-way-to-pickup-1-24.png"),
            color: "#c4bbfe",
        },
    ],
    taxis: [],
    taxiFilters: [false, true, 1, 3]
}

const mutations = {
    ...make.mutations(state),

    addTaxis(state, taxis) {
        state.taxis = taxis
        console.log("addTaxis", taxis);
    },

    addTaxi(state, taxi) {
        state.taxis = [...state.taxis, taxi];
        console.log("addTaxi", taxi);
    },

    updateTaxi(state, taxi) {
        state.taxis = state.taxis.map(x => {
            if (x.macAddress === taxi.macAddress) {
                let result = { ...x, ...taxi };
                console.log("updateTaxi", result);
                return result;
            }
            return x;
        });
    }
};

const actions = {
    ...make.actions(state),

    loadTaxis: async ({ commit }) => {
        try {
            let driverPromise = axios.get('/Driver/GetAllDriversWithStatus');
            let devicePromise = axios.get('/Device/GetDevicesFromQueue');
            let drivers = (await driverPromise)?.data?.data || [];
            let devices = (await devicePromise)?.data || [];

            let taxis = devices?.map(x => {
                x.isDriver = false;
                if (x.ownedDriverId && x.ownedDriverId > -1) {
                    let driver = drivers.find(y => y.driverId === x.ownedDriverId);
                    if (driver) {
                        x = { ...driver, ...x }
                        x.isDriver = true;
                    }
                }
                return x
            }) || [];

            commit('addTaxis', taxis)
        } catch (err) {
            console.log('loadTaxis', err);
        }
    },

    addNewTaxi: ({ commit }, device) => {
        if (device != null) {
            commit('addTaxi', device)
        }
    },

    updateDriver: ({ commit, getters }, driver) => {
        if (driver != null) {
            let taxi = getters.getTaxiByUsername(driver.username);
            commit('updateTaxi', { ...taxi, ...driver })
        }
    },

    updateDevice: ({ commit }, device) => {
        if (device != null) {
            let taxi = getters.getTaxiByMacAddress(device.macAddress);
            commit('updateTaxi', { ...taxi, ...device })
        }
    },
}

const getters = {
    getTaxiByMacAddress: (state) => (mac) => {
        return state.taxis.find(x => x.macAddress === mac);
    },
    getTaxiByDriverId: (state) => (id) => {
        return state.taxis.find(x => x.driverId === id);
    },
    getTaxiByUsername: (state) => (username) => {
        return state.taxis.find(x => x.isDriver && x.firstName === username);
    },
    getTaxiStatus: (state) => (id) => {
        let status = state.statuses.find(x => x.id === id);
        if (!status) {
            status = {
                id: "",
                text: "",
                icon: "",
                color: "",
            }
        }
        return status;
    },

    activeTaxis: (state) => {
        return state.taxis.reduce((total, device) => {
            if (device.active) {
                total.push(device);
            }
            return total;
        }, []);
    },
    inActiveTaxis: (state) => {
        return state.taxis.filter(x => !x.isDriver && !x.active);
    },
    loggedOutTaxis: (state) => {
        return state.taxis.filter(x => (x.isDriver && !x.active) && (x.driverStatus === 1 || x.driverStatus === 2));
    },
    loggedInTaxis: (state) => {
        return state.taxis.filter(x => x.isDriver && x.driverStatus === 3);
    },

    getTaxisByStatus: (state, getters) => (id) => {
        switch (id) {
            case false:
                return getters.inActiveTaxis;
            case true:
                return getters.activeTaxis;
            case 1:
                return getters.loggedOutTaxis;
            case 3:
                return getters.loggedInTaxis;
            default:
                return [];
        }
    },
    getTaxisCountByStatus: (state, getters) => (id) => {
        switch (id) {
            case false:
                return getters.inActiveTaxis.length;
            case true:
                return getters.activeTaxis.length;
            case 1:
                return getters.loggedOutTaxis.length;
            case 3:
                return getters.loggedInTaxis.length;
            default:
                return [];
        }
    },
    getTaxisOptionMenu: (state, getters) => {
        return [{
            id: false,
            type: 'filter',
            title: "(" + getters.getTaxisCountByStatus(false) + ") Inactive",
            checkbox: true
        }, {
            id: true,
            type: 'filter',
            title: "(" + getters.getTaxisCountByStatus(true) + ") Active",
            checkbox: true
        },
        {
            id: 1,
            type: 'filter',
            title: "(" + getters.getTaxisCountByStatus(1) + ") Logged out",
            checkbox: true
        },
        {
            id: 3,
            type: 'filter',
            title: "(" + getters.getTaxisCountByStatus(3) + ") Logged in",
            checkbox: true
        },
        ]
    },
    getSuburbs: (state) => {
        var suburbs = state.taxis.reduce((suburbs, taxi) => {
            if (taxi.address && typeof taxi.address === 'string') {
                let address = taxi.address.split(",");
                if (address.length > 1) {
                    let suburb = address[address.length - 2];
                    let index = suburbs.findIndex((x) => x.suburb === suburb);
                    if (index > -1) {
                        suburbs[index].taxis.push(taxi);
                    } else {
                        suburbs.push({ suburb, taxis: [taxi] });
                    }
                }
            }
            return suburbs;
        }, []);
        suburbs.sort((a, b) => b.taxis.length - a.taxis.length);
        return suburbs;
    },
    getTaxiFilters: (state) => {
        return state.taxiFilters;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}